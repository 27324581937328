import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { CountUp } from 'countup.js';
import party from "party-js";


gsap.registerPlugin(ScrollTrigger);


const runTicker = () => {
  const tickerRefNum = document.querySelector('[data-ticker]');

  if (!tickerRefNum) {
    return;
  }

  const useConfetti = Object.keys(tickerRefNum.dataset).includes(
    'tickerUseConfetti'
  );

  let didConfettiExplode = false;
  let start = tickerRefNum.dataset.tickerStart;
  let end = tickerRefNum.dataset.tickerEnd;

  const fetchEquityOwnersData = async () => {
    try {
      const res = await fetch(
        'https://app.carta.com/ds-api/v1alpha1/marketing/equity-owners',
        { mode: 'cors' }
      );
      const json = await res.json();

      if (json.equityOwners.yesterday.quantity > json.equityOwners.today.quantity) {
        throw 'ds-api equity owner quantity response is invalid.';
      }

      if (json.equityOwners.yesterday.quantity < 2000000 || json.equityOwners.today.quantity < 2000000) {
        throw 'ds-api equity owner quantity is less than 2M';
      }

      return json;
    } catch (error) {
      console.log(error)
      return {
        'equityOwners': {
          'yesterday': {
            'quantity': 2069341
          },
          'today': {
            'quantity': 2069384
          }
        }
      }
    }
  };

  const runConfetti = () => {
    const colors = [
      new party.Color(136, 234, 235, 1),
      new party.Color(196, 231, 77, 1),
      new party.Color(248, 214, 72, 1),
      new party.Color(84, 137, 173, 1),
      new party.Color(101, 191, 177, 1),
      new party.Color(101, 191, 177, 1),
      new party.Color(237, 145, 74, 1),
      new party.Color(227, 238, 243, 1),
      new party.Color(217, 243, 239, 1),
      new party.Color(255, 243, 236, 1),
      new party.Color(239, 234, 232, 1),
      new party.Color(252, 249, 244, 1),
    ];
    party.confetti(tickerRefNum, {
      count: party.variation.range(100, 150),
      color: colors,
    });
  };

  const runEquityOwnersAnimation = async () => {
    const res = await fetchEquityOwnersData();

    if (res.equityOwners) {
      start = res.equityOwners.yesterday.quantity;
      end = res.equityOwners.today.quantity;
    }

    const counterUp = new CountUp(tickerRefNum, end, {
      startVal: start,
      duration: 2,
    });

    gsap.set(tickerRefNum, {
      opacity: 0,
    });

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: tickerRefNum,
        start: 'top bottom-=50',
        onToggle: async ({ isActive }) => {
          if (isActive && didConfettiExplode === false) {
            counterUp.start(() => {
              if (useConfetti && didConfettiExplode === false) {
                runConfetti();
                didConfettiExplode = true;
              }
            });
          } else {
            counterUp.pauseResume();
          }
        },
      },
    });

    tl.to(tickerRefNum, { opacity: 1, duration: 2 });
  };

  runEquityOwnersAnimation();
};

document.addEventListener('DOMContentLoaded', () => {
  // run for Front End View
  runTicker();

  // run for Edit View
  if (window.acf) {
    window.acf.addAction(`render_block_preview/type=stats-features`, runTicker);
  }
});
